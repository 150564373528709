import React, { useState, useEffect, useRef, useCallback } from 'react';
import cn from 'classnames';
import { scroller } from 'react-scroll';
import { Link, SubNavigationItem } from '~/common/models';
import {
  Button,
  ButtonTypeEnum,
  SvgIcon,
} from '~/common/components/ui-elements';
import styles from './index.module.scss';
import { joinPath } from '~/common/utils';

interface Section {
  id: number;
  offsetTop: number;
  offsetHeight: number;
}

interface NavigationProps {
  items: SubNavigationItem[];
  navigationSticky: boolean;
  backgroundColor: string;
  color: string;
  ctaButton: Link;
  ctaButtonStyle: keyof typeof ButtonTypeEnum;
}

export const Navigation = React.memo<NavigationProps>(
  ({
    items,
    navigationSticky,
    backgroundColor,
    color,
    ctaButton,
    ctaButtonStyle,
  }) => {
    const containerRef = useRef<HTMLDivElement | null>(null);
    const [isSticky, setIsSticky] = useState<boolean>(false);
    const [isExpand, setIsExpand] = useState<boolean>(false);
    const [activeContentId, setActiveContentId] = useState<number>(0);
    const [contentSections, setContentSections] = useState<Section[]>([]);

    const handleClick = (nav: SubNavigationItem) => {
      setActiveContentId(nav.contentId);
      const target = `cid-${nav.contentId}`;
      scroller.scrollTo(target, {
        smooth: true,
        offset: -90,
        ignoreCancelEvents: true,
      });

      if (!!nav.urlSubPath) {
        replaceURLWithoutReloading(nav.urlSubPath);
      } else {
        replaceURLWithoutReloading('');
      }
    };

    const replaceURLWithoutReloading = useCallback((newUrl: string) => {
      if (window.history && window.history.replaceState) {
        window.history.replaceState(
          null,
          '',
          joinPath(
            `/${window['pageUrlSegment'] || window.location.pathname}`,
            window.location.search.includes('utm') ||
              window.location.search.includes('gclid')
              ? joinPath(newUrl, window.location.search)
              : newUrl
          )
        );
      }
    }, []);

    const checkScrollPosition = (sections: Section[]) => {
      if (isExpand) return;

      let activeLinkId = 0;
      const pageYOffset = window.scrollY;

      for (const section of sections) {
        const sectionTop = section.offsetTop - 80;
        const sectionBottom = sectionTop + section.offsetHeight;
        if (pageYOffset >= sectionTop && pageYOffset < sectionBottom) {
          activeLinkId = section.id;
          break;
        }
      }

      const navItem = items.find((x) => x.contentId === activeLinkId);
      replaceURLWithoutReloading(navItem?.urlSubPath || '');
      setActiveContentId(navItem?.contentId || 0);

      if (containerRef.current && sections.length > 0) {
        const firstSection = sections[0];
        const stickOnTop = firstSection.offsetTop - 100 <= pageYOffset;
        setIsSticky(navigationSticky && stickOnTop);
      }
    };

    useEffect(() => {
      for (const el of document.querySelectorAll('[id^="cid-"]')) {
        const divEl = el as HTMLDivElement;
        const section: Section = {
          id: Number(el.id.split('-')[1]),
          offsetHeight: divEl.offsetHeight,
          offsetTop: divEl.offsetTop,
        };
        setContentSections((list) => [...list, section]);
      }
    }, []);

    useEffect(() => {
      if (contentSections.length > 0) {
        window.addEventListener('scroll', () =>
          checkScrollPosition(contentSections)
        );
        return () => {
          window.removeEventListener('scroll', () =>
            checkScrollPosition(contentSections)
          );
        };
      }
    }, [contentSections]);

    useEffect(() => {
      const timeoutId = setTimeout(() => {
        if (window.location) {
          const pathnames = window.location.pathname.split('/');
          const lastPath = pathnames[pathnames.length - 1];
          if (lastPath) {
            const navItem = items.find((x) => x.urlSubPath === lastPath);
            if (!!navItem) {
              handleClick(navItem);
            }
          }
        }
      }, 1000);

      return () => clearTimeout(timeoutId);
    }, []);

    return (
      <>
        <div
          key={1}
          id="sub-navigation"
          ref={containerRef}
          className={cn(styles.navigation, {
            [styles.navSticky]: isSticky,
            [styles.navExpand]: isExpand,
          })}
          style={{ backgroundColor }}
        >
          <div className="container">
            <div className={styles.navWrapper}>
              <ul className={styles.navItems}>
                {items.map((item, index) => (
                  <li
                    key={index}
                    className={cn(styles.navItem, {
                      [styles.isActive]: item.contentId === activeContentId,
                    })}
                  >
                    <a
                      href={item.urlSubPath}
                      onClick={(e) => {
                        e.preventDefault();
                        handleClick(item);
                      }}
                      style={{ color }}
                    >
                      {item.displayText}
                    </a>
                  </li>
                ))}
              </ul>

              {ctaButton && (
                <div className={styles.ctaButton}>
                  <Button
                    onClick={() => window.open(ctaButton.url, ctaButton.target)}
                    type={ctaButtonStyle}
                    buttonSize="medium"
                  >
                    {ctaButton.name}
                  </Button>
                </div>
              )}

              <div
                className={styles.downArrow}
                onClick={() => setIsExpand(!isExpand)}
              >
                <SvgIcon
                  type={isExpand ? 'close' : 'chevronDown'}
                  size={1.25}
                />
              </div>
            </div>
          </div>
        </div>

        {isExpand && (
          <div key={2} className={styles.mobileNavItems}>
            {items.map((item, index) => (
              <div
                key={index}
                className={styles.navItemMobile}
                onClick={() => {
                  setIsExpand(false);
                  handleClick(item);
                }}
              >
                {item.displayText}
              </div>
            ))}

            {ctaButton && (
              <div className={styles.ctaButtonMobile}>
                <Button
                  block
                  onClick={() => window.open(ctaButton.url, ctaButton.target)}
                  type={ctaButtonStyle}
                  buttonSize="medium"
                >
                  {ctaButton.name}
                </Button>
              </div>
            )}
          </div>
        )}
      </>
    );
  }
);

import React from 'react';
import { Button, SvgIcon } from '@ui-elements';
import { StickyContent } from '~/common/models';
import styles from './index.module.scss';
import { adjustScale } from '@dnd-kit/core/dist/utilities';

interface StickyBlueRibbonProps {
  content: StickyContent;
}
export const StickyBlueRibbon = React.memo<StickyBlueRibbonProps>(
  ({ content }) => {
    const [visible, setVisible] = React.useState<boolean>(true);

    React.useEffect(() => updateBodyClass(true), []);

    const updateBodyClass = (add: boolean) => {
      if (!!document) {
        const body = document.body;
        const stickyPromo = 'sticky-promo';
        if (!body.classList.contains(stickyPromo) && add) {
          body.classList.add(stickyPromo);
        } else {
          body.classList.remove(stickyPromo);
        }
      }
    };

    const toggleVisible = () => {
      updateBodyClass(false);
      setVisible(false);
    };

    return visible ? (
      <div id="stickBlueRibbon" className={styles.stickyBlueRibbon}>
        <div>
          <p className={styles.blueRibbonTitle}>{content.title}</p>
          <p className={styles.blueRibbonSubTitle}>{content.subTitle}</p>
          {content.callToAction && (
            <div className={styles.blueRibbonCta}>
              <Button
                type="secondary"
                buttonSize="small"
                href={content.callToAction.url}
                target={content.callToAction.target}
              >
                {content.callToAction.name}
              </Button>
            </div>
          )}

          <div className={styles.blueRibbonCloseButton} onClick={toggleVisible}>
            <SvgIcon type="close" size={1.6} color="#fff" />
          </div>
        </div>
      </div>
    ) : null;
  }
);
